import React from 'react';
import './LD.css';

class LD extends React.Component {
    render() {
        
        return (
            <div className="LDroot">
                <div className="LD1inner">
                    <h1> Legal Disclaimers </h1>
                    <br/><br/>
                    These Legal Disclaimers are the policies of Vendido LLC (“Vendido”) that apply to the use of the website, www.vendidollc.com (the “Site”) and they govern your use of the Site.<br/><br/>

                    <h3>NOTICE REGARDING COPYRIGHT OR TRADEMARK</h3>
                    <br/>
                    The contents of all materials available on the Site are copyrighted unless otherwise indicated. All rights are reserved, and content may not be reproduced, downloaded, disseminated, published, or transferred in any form or by any means, except with prior written consent of Vendido LLC. Requests for permission to reuse copyright content or if you believe your work is being used on the Site in a way that constitutes copyright infringement, should be made to: Vendido LLC – 127 Park Place Drive, Reading, Pennsylvania 19608; marketing@vendidollc.com.<br/><br/><br/>
                    <h3>FAIR HOUSING DISCLAIMER</h3>
                    <br/>
                    Vendido LLC encourages and supports an advertising and marketing program in which there are no barriers to obtaining housing due to race, color, religion, sex, handicap, familial status, or national origin. All residential real estate information on the website from which this page is linked is subject to the Federal Fair Housing Act, Title VIII of the Civil Rights Act of 1968, as amended, which makes it illegal to advertise “any preference, limitation, or discrimination because of race, color, religion, sex, handicap, familial status, or national origin, or intention to make any such preference, limitation or discrimination.” Your state or local jurisdiction may impose additional requirements. We are committed to the letter and spirit of the United States policy for the achievement of equal housing opportunities.<br/><br/><br/>
                    <h3>NO OFFER TO SELL OR NO OFFER OF REPRESENTATION</h3><br/>
                    NO INFORMATION OR MATERIAL ON THE SITE SHALL BE CONSTRUED IN ANY WAY AS AN OFFER OR SOLICITATION FOR SALE OF REAL ESTATE OR AS AN OFFER OF REPRESENTATION FOR BROKER OR AGENT SERVICES AS IT RELATES TO ANY REAL ESTATE TRANSACTION. Vendido is a software as a service platform which utilizes patent pending technology to assist real estate agents and real estate service providers in providing information and services for their professional business operations. It does not provide real estate agent or broker services nor does it assist any individuals or entities with the purchase or sale of real estate. The information on the Site is provided for information purposes only. You should seek advice and proper legal counsel with respect to all closing costs, timing considerations, financing options, default provisions, zoning implications, renovation considerations and appropriate transfer taxes.
                    <br/><br/><br/>
                    <h3>ERRORS, INACCURACIES, AND OMISSIONS</h3><br/>
                    All data and information provided by Vendido LLC on the Site regarding real property, for sale, purchase, rental and/or financing, is deemed reliable but not guaranteed. Any listing on the Site may be updated, changed, modified, withdrawn, sold or rented at any time without notice. Any furnishings, decorations, and artist representations are provided for illustrative purposes only and should not be relied upon. Images may also include decorative items, design elements and furniture which are not for purchase even upon additional payment. Square footages are approximate and may be verified by consulting a professional architect or engineer. The information on the Site is not intended to be comprehensive, and may not be accurate, up-to-date or applicable to any particular case.
                    <br/><br/><br/>
                    <h3>WARRANTIES AND CONDITIONS</h3><br/>
                    No warranties, whether express or implied by statute or otherwise, including but not limited to, the implied warranties or conditions of merchantability or satisfactory quality and fitness for a particular purpose and non-infringement, are made as to the accuracy of any descriptions and/or other details and such information may be subject to errors, omissions, changes of price, tenancies, commissions, prior sales, leases or financing, or withdrawal without notice. Neither listing broker(s) or information provider(s) shall be responsible for any typographical errors, misinformation, or misprints, and shall be held totally harmless from same. Listing(s) information is provided for consumers personal, non-commercial use and may not be used for any other purpose other than to identify prospective properties consumers may be interested in purchasing.
                    All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, whether express or implied by statute or otherwise, including but not limited to, the implied warranties or conditions of merchantability or satisfactory quality and fitness for a particular purpose and non-infringement, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site including the quality or condition of the property, the suitability of the property for any and all activities and uses which purchaser may conduct thereon, compliance by the property with any laws, rules, ordinances or regulations if any applicable governmental authority, or habitability, merchantability, or fitness for any particular purpose.
                    UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND, INCLUDING BUT NOT LIMITED TO, CONSEQUENTIAL DAMAGES, PUNITIVE DAMAGES, OR OTHER INCIDENTAL OR SPECIAL DAMAGES, INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.<br/><br/>
                    <br/>
                    <h3>THIRD PARTY LINKS</h3><br/>
                    The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABIITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDER OF PRODUCTS OR SERVICES.<br/><br/><br/>
                    <h3>ACCESS BY MINORS</h3><br/>
                    To respect the privacy of children and to comply with the Children’s Online Privacy Protection Act, Vendido doe not knowingly provide products or services to children under the age of 13. The Site is a general audience site that is not designed nor intended to collect personal information from children under the age of 13. If you are under 13 years of age, you should not request any information nor provide any personal information to the Site.
                    Vendido may at any time revise these disclaimers by updating the website. You are bound by such revisions and should therefore periodically visit the website to review the then current disclaimers for which you are bound.
                </div>
            </div>
        );
    }
}

export default LD;